import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    productSheetForm: {
        padding: "40px",
        marginTop: "80px",
        marginBottom: "80px",
        display: "table",
        maxWidth: "600px",
        background: "#fff",
    },
    productSheetHeader: {
        fontFamily: "Space Grotesk, sans-serif",
        fontSize: "22px",
        lineHeight: "1.2em",
        marginRight: "22px",
        marginLeft: "10px",
        color: "#333",
        fontWeight: 400,
        fontStyle: "normal",
        letterSpacing: ".02em",
        textTransform: "none",
        marginBottom: "55px",
    },
    close: {
        
    },
    halfWidthInput: {
        width: "50%",
    },
    wholeWidthInput: {
        width: "100%",
    },
    spacingWidthInput: {
        width: "48%",
    },
    singleLineInput: {
        height: "46px",
    },
    input: {
        margin: "6px 0 4px",
        background: "#fafafa",
        border: "1px solid #ccc",
        color: "black",
        fontFamily: "sans-serif",
        fontSize: "17px !important",
        fontWeight: 700,
        borderRadius: "2px",
    },
    inputHeader: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "19px",
        letterSpacing: ".03em",
        textTransform: "none",
        color: "#4b7391",
    },
    infoInputField: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "12px",
        letterSpacing: ".03em",
        lineHeight: "1.5em",
        textTransform: "none",
        color: "#4b7391",
    },
    textarea: {
        height: "100px",
    },
    submitButton: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#272727",
        border: "2px solid #005999",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Space Grotesk, sans-serif",
        textTransform: "none",
        lineHeight: "1em",
        padding: "1em 2.5em",
        height: "42px",
        marginTop: "20px",
        cursor: "pointer"
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        position: "absolute",
        right: 40,
    },
    marginLeft: {
        marginLeft: "4%",
    },
}));