import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

import footerLogo from '../../assets/Logo+New+Marimetrics-13.WebP';

const Footer = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.footerDiv}>
                <div className={classes.floatLeft}>
                    <Typography className={`${classes.footerTypography} ${classes.footerHeader}`} variant="h2">Marimetrics Technologies Inc.</Typography>
                    <Typography className={classes.footerTypography} variant="h2">27 Parker Street</Typography>
                    <Typography className={classes.footerTypography} variant="h2">Dartmouth</Typography>
                    <Typography className={classes.footerTypography} variant="h2">NS Canada, B2Y 4T5</Typography>
                    <Typography className={classes.footerTypography} variant="h2">+1.902.877.4967</Typography>
                    <Typography className={`${classes.footerTypography} ${classes.footerCopyright}`} variant="h2">©️ 2023 Marimetrics Technologies Inc.</Typography>
                </div>
                <img className={`${classes.floatRight} ${classes.footerImage}`} src={footerLogo} alt="Marimetrics" />
            </div>
        </>
    )
}

export default Footer
