import { makeStyles } from '@material-ui/core/styles';

import productSheetImage from '../../assets/marimetrics+hero.WebP';

export default makeStyles(() => ({
    main: {
        marginTop: "90px",
    },
    imageShips: {
        backgroundImage: `url(${productSheetImage})`,
        height: "597.6px",
        padding: "79px 80px",
        paddingTop: "221px",
        paddingBottom: "221px",
    },
    typography: {
      color: "white",
      fontWeight: 700,
      fontFamily: "Space Grotesk, sans-serif",
      fontSize: "42px",
      margin: ".67em 0",
      lineHeight: "46.2px",
      letterSpacing: ".01em",
      width: "50%",
    },
    dividerDiv: {
        padding: "79px 80px",
    },
    dividerTypography: {
        textAlign: "center",
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "26px",
        letterSpacing: ".02em",
        lineHeight: "1.2em",
        textTransform: "none",
    },
    productDiv: {
        color: "#fff",
        backgroundColor: "#232d38",
        padding: "79px 80px",
    },
    productTypography: {
        textAlign: "center",
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "700",
        fontStyle: "normal",
        fontSize: "42px",
        letterSpacing: ".01em",
        lineHeight: "1.1em",
        textTransform: "none",
        margin: ".67em 0",
    },
    productUl: {
        textAlign: "left",
    },
    productLi: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "19px",
        letterSpacing: ".03em",
        lineHeight: "1.5em",
        textTransform: "none",
        listStyleType: "none",
    },
    bulletPoints: {
        maxWidth: "24px",
        width: "24px",
        height: "24px",
        padding: "17px",
        verticalAlign: "middle",
    },
    divCentered: {
        margin: "0 auto",
        display: "table",
    },
    productSheetDiv: {
        padding: "79px 45px",
    },
    productSheetImage: {
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "17px",
        width: "240px",
        height: "240px",
    },
    productSheetTypography: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "300",
        fontStyle: "normal",
        fontSize: "40px",
        letterSpacing: ".02em",
        lineHeight: "1.4em",
        textTransform: "none",
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "17px",
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    productSheetButton: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#005999",
        border: "2px solid #005999",
        fontSize: "20px",
        fontWeight: 600,
        fontFamily: "Space Grotesk, sans-serif",
        textTransform: "none",
        margin: "17px",
        '&:hover': {
            background: "rgba(0, 89, 153,.8)"
        },
    },

    productSheetCloseButton: {
        position: "relative",
        top: "140px",
        left: "620px",
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        fontWeight: "bolder"
    },
    
    contactUsDiv: {
        backgroundColor: "#005897",
        padding: "79px 80px",
        textAlign: "center",
    },
    contactUsTypography: {
        color: "#fff",
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "26px",
        letterSpacing: ".02em",
        lineHeight: "1.2em",
        textTransform: "none",
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "17px",
    },
    contactUsButton: {
        fontFamily: "Space Grotesk, sans-serif",
        fontSize: "20px",
        fontWeight: 600,
        fontStyle: "normal",
        textTransform: "none",
        letterSpacing: ".03em",
        color: "#fff",
        borderColor: "#fff",
        borderRadius: 0,
        padding: "21px 34px",
        marginTop: "17px",
        marginBottom: "17px",
        border: "2px solid #ffffff",
        '&:hover': {
            backgroundColor: "#fff",
            color: "#1d1d1d",
        },
    },
}));
