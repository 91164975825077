import React from 'react';
import { Navbar, Footer, Body, ContactUs } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

                     

const App = () => {

    return (
        <Router>
            <div>
                <Navbar />
                <Switch>
                    <Route exact path="/">
                        <Body/>
                    </Route>
                    <Route exact path="/contact-us">
                       <ContactUs />
                    </Route>
                </Switch>
                <Footer />
            </div>
        </Router>
    )
}

export default App;
