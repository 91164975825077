import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import useStyles from './styles';

import imageOne from '../../assets/image-asset.WebP';
import imageTwo from '../../assets/Marimetrics+Website+Illustrations-01.WebP';
import imageThree from '../../assets/Marimetrics+Website+Illustrations-03.WebP';
import imageFour from '../../assets/Marimetrics+Website+Illustrations-04.WebP';

const images = [
    {id: 1, src: imageOne, header: "Oil & Gas", description: "Monitor bulk hydrocarbons or trace level impurities in line, in air, or in situ."},
    {id: 2, src: imageTwo, header: "Maritime Industry", description: "Move to condition-based asset maintenance through monitoring of ship propulsion with detailed insights on exhaust composition."},
    {id: 3, src: imageThree, header: "Aquaculture", description: "Monitor advanced indicators of aquatic animal health and water quality for critical insights in real time."},
    {id: 4, src: imageFour, header: "Science", description: "Build high dynamic range, high sensitivity observations of bulk and trace constituents into your surveys and time series in air or water."},
];

const ImageGrid = () => {
    const classes = useStyles();
    return (
        <div className={classes.imageGridDiv}>
            <Typography className={classes.headerTypography} variant="h2">Solutions For</Typography>
            <Grid container justify="center">
                {images.map((image) => (
                    <Grid item key={image.id} xs={12} sm={6} md={6} lg={6}> 
                        <img alt={image.id} className={classes.gridImage} src={image.src}></img>
                        <Typography className={classes.headerImage} variant="h2">{image.header}</Typography>
                        <Typography className={classes.descriptionImage} variant="h3">{image.description}</Typography>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default ImageGrid
