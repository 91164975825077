import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    imageGridDiv: {
        backgroundColor: "#f2f6f8",
        padding: "79px 30px",
    },
    gridImage: {
        width: "310px",
        height: "310px",
        paddingTop: "17px",
        paddingBottom: "17px",
        display: "block",
        margin: "0 auto",
    },
    headerTypography: {
        textAlign: "center",
        fontFamily: "Space Grotesk, sans-serif",
        fontStyle: "normal",
        fontSize: "40px",
        letterSpacing: ".02em",
        lineHeight: "1.4em",
        textTransform: "none",
        fontWeight: "bold",
        color: "#0d4b77",
        textShadow: "0px 2px, 1px 0px, 1px 0px",
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "17px",
    },
    headerImage: {
        textAlign: "center",
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "300",
        fontStyle: "normal",
        fontSize: "40px",
        letterSpacing: ".02em",
        lineHeight: "1.4em",
        textTransform: "none",
        color: "#0d4b77",
        marginBottom: "33.2px",
    },
    descriptionImage: {
        textAlign: "center",
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "19px",
        letterSpacing: ".03em",
        lineHeight: "1.5em",
        textTransform: "none",
        color: "#4b7391",
        marginTop: "19px",
        marginLeft: "10px",
        marginRight: "10px"
    },
}));