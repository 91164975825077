import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    contactUsForm: {
        paddingTop: "169px",
        margin: "0 auto",
        display: "table",
        maxWidth: "600px",
    },
    contactUsHeader: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: "300",
        fontStyle: "normal",
        fontSize: "40px",
        letterSpacing: ".02em",
        lineHeight: "1.4em",
        textTransform: "none",
        margin: "0 auto",
    },
    halfWidthInput: {
        width: "50%",
    },
    wholeWidthInput: {
        width: "100%",
    },
    spacingWidthInput: {
        width: "48%",
    },
    singleLineInput: {
        height: "46px",
    },
    input: {
        margin: "6px 0 4px",
        background: "#fafafa",
        border: "1px solid #ccc",
        color: "black",
        fontFamily: "sans-serif",
        fontSize: "17px !important",
        fontWeight: 700,
        borderRadius: "2px",
    },
    inputHeader: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "19px",
        letterSpacing: ".03em",
        textTransform: "none",
        color: "#4b7391",
    },
    infoInputField: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "12px",
        letterSpacing: ".03em",
        lineHeight: "1.5em",
        textTransform: "none",
        color: "#4b7391",
    },
    textarea: {
        height: "100px",
    },
    submitButton: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#005999",
        border: "2px solid #005999",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Space Grotesk, sans-serif",
        textTransform: "none",
        lineHeight: "1em",
        padding: "1em 2.5em",
        height: "42px",
        '&:hover': {
            background: "rgba(0, 89, 153,.8)"
        },
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    marginLeft: {
        marginLeft: "4%",
    },
}));