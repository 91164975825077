import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    divCentered: {
        margin: "0 auto",
        display: "table",
    },
    footerDiv: {
        padding: "141px 80px",
    },
    footerHeader: {
        marginBottom: "15px",
    },
    footerTypography: {
        fontFamily: "Space Grotesk, sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "19px",
        letterSpacing: ".03em",
        lineHeight: "1.5em",
        textTransform: "none",
    },
    footerImage: {
        width: "300px",
        height: "102px",
    },
    footerCopyright: {
       marginTop: "102px",
       marginBottom: "37px",
    },
}));