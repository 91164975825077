import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 0;

export default makeStyles((theme) => ({
  navBar: {
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    height: "90px",
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    paddingLeft: "70px",
    paddingRight: "70px",
    paddingTop: "20px",
  },
  logo: {
    maxWidth: "256px",
    width: "256px",
    height: "29.8667px"
  },
  logoSmall: {
    maxWidth: '150px',
    width: "150px",
    height: "28px",
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 0,
    color: "#005999",
    border: "2px solid #005999",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Space Grotesk, sans-serif",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "rgb(0, 89, 153)",
      color: "white",
    },
  },
  hamburgerSlideMenu: {
    backgroundColor: "#0d4b77",
    top: 0,
  },
  slideMenuLogo: {
    height: "30px",
    marginTop: "36px",
    marginLeft: "36px",
  },
  contactUsSlideMenu: {
    fontFamily: "Space Grotesk, sans-serif",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "25px",
    letterSpacing: ".02em",
    lineHeight: "1.2em",
    textTransform: "none",
    color: "#fff",
    marginTop: "25px",
    marginLeft: "36px",
    textDecoration: "none",
  },
  contactUsTrademarkSlideMenu: {
    fontFamily: "Space Grotesk, sans-serif",
    position: "fixed",
    bottom: "5%",
    marginLeft: "36px",
    color: "#8294a2",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "19px",
    letterSpacing: ".03em",
    lineHeight: "1.5em",
    textTransform: "none",
  },
}));